import * as KatalMetrics from '@katal/metrics';
import KatalMetricsDriverSushi from '@katal/metricsDriverSushi';
import { ArgoMetricsDriver } from './ArgoMetricsDriver';
import KatalMetricsDriverConsoleLogJson from '@katal/metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import getRealmName from './realmResolver';
import { getArgoConfig } from '../../window';
import { credentialStorage } from '../CredentialStorage';

const SITENAME: string = 'Argo';
const CN_REGION: string = 'cn-northwest-1';

export interface ArgoMetricsConfig {
  appName: string,
  region: string,
  frameworkStage: string,
  cell: string,
  browserName: string,
  deviceType: string,
  deviceOS: string,
  locale: string,
  applicationVisitId: string,
  argoSessionId: string,
  loggerUrl?:string
}

// Error Handler
export const metricsErrorHandler = (err: Error) => {
  return err;
};

// Make Driver
export const makeMetricsDriver = (metricsConfig: ArgoMetricsConfig): KatalMetrics.MetricsDriver => {
  // If not in production then log to console
  if (metricsConfig.frameworkStage === 'dev') {
    return new KatalMetricsDriverConsoleLogJson();
  }

  // Send PersonalStack metrics to PreProd (which currently does not get to PMET: https://issues.amazon.com/issues/NEXUSJET-64)
  const config = getArgoConfig() || {};
  let domain = 'test';
  if (!config.argo || !config.argo.isPersonalStack) {
    domain = 'prod';
  }

  const sushiMetricsDriver = new KatalMetricsDriverSushi.Builder()
    .withDomainRealm(domain, getRealmName(metricsConfig.region))
    .withErrorHandler(metricsErrorHandler)
    .build();

  // If the region is CN and the logging URL is defined, add the ArgoMetricsDriver wrapper to the Driver, otherwise just return the MetricsDriverSushi
  if (metricsConfig.region === CN_REGION && metricsConfig.loggerUrl) {
    return new ArgoMetricsDriver(sushiMetricsDriver, metricsConfig.loggerUrl);
  } else {
    return sushiMetricsDriver;
  }
};

// Make Initial Context
const makeMetricsContext = (metricsConfig: ArgoMetricsConfig, serviceNameOverride?: string) => {
  const serviceName: string = (serviceNameOverride) ?
    serviceNameOverride
    : metricsConfig.appName + '.' + metricsConfig.frameworkStage +  '.' + metricsConfig.region + '.' + metricsConfig.cell;
  return new KatalMetrics.Context.Builder()
    .withSite(SITENAME)
    .withServiceName(serviceName)
    .withRelatedMetrics(
        new KatalMetrics.Metric.String("Browser_Name", metricsConfig.browserName),
        new KatalMetrics.Metric.String("Device_Type", metricsConfig.deviceType),
        new KatalMetrics.Metric.String("Device_OS", metricsConfig.deviceOS),
        new KatalMetrics.Metric.String("Locale", metricsConfig.locale),
        new KatalMetrics.Metric.String("Application_Visit_Id", metricsConfig.applicationVisitId),
        new KatalMetrics.Metric.String("Argo_Session_Id", metricsConfig.argoSessionId),
        new KatalMetrics.Metric.String("User_Unique_Id", credentialStorage.getUserUniqueId())

    )
    .build();
};

// Make Publisher
export const makePublisher = (metricsConfig: ArgoMetricsConfig, serviceNameOverride?: string) => {
  const metricsDriver = makeMetricsDriver(metricsConfig);

  const initialMetricsContext = makeMetricsContext(metricsConfig, serviceNameOverride);
  return new KatalMetrics.Publisher(
    metricsDriver,
    metricsErrorHandler,
    initialMetricsContext
  );
};

// Initial publisher
const initialMetricsPublisher = (metricsConfig: ArgoMetricsConfig) => makePublisher(metricsConfig);

export default initialMetricsPublisher;
